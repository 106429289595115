/**
 * Layout: Russian
 */
export default {
  layout: {
    default: [
      '\u0451 1 2 3 4 5 6 7 8 9 0 - = {bksp}',
      '{tab} \u0439 \u0446 \u0443 \u043a \u0435 \u043d \u0433 \u0448 \u0449 \u0437 \u0445 \u044a \\',
      '{lock} \u0444 \u044b \u0432 \u0430 \u043f \u0440 \u043e \u043b \u0434 \u0436 \u044d {enter}',
      '{shift} / \u044f \u0447 \u0441 \u043c \u0438 \u0442 \u044c \u0431 i - {shift}',
      '.com @ {space}',
    ],
    shift: [
      '` 1 2 3 4 5 6 7 8 9 0 - = {bksp}',
      '{tab} Q W E R T Y U I O P { } |',
      '{lock} A S D F G H J K L : " {enter}',
      '{shift} Z X C V B N M < > ? {shift}',
      '.com @ {space}',
    ],
  },
}
