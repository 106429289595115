<template>
  <div :class="keyboardClass" />
</template>

<script>
import './keyboard-styles.css'
import Keyboard from 'simple-keyboard'
import layout from './russian'

export default {
  name: 'SimpleKeyboard',
  props: {
    keyboardClass: {
      default: 'simple-keyboard',
      type: String,
    },
    input: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    keyboard: null,
    displayRu: {
      '{bksp}': '<-- стереть',
      '{shift}': 'English',
    },
    displayEn: {
      '{bksp}': '<-- стереть',
      '{shift}': 'Русский',
    },
  }),
  watch: {
    input(input) {
      this.keyboard.setInput(input)
      this.keyboard.setCaretPosition(input.length)
    },
  },
  mounted() {
    this.keyboard = new Keyboard(this.keyboardClass, {
      onChange: this.onChange,
      onKeyPress: this.onKeyPress,
      ...layout,
      display: this.displayRu,
    })
  },
  methods: {
    onChange(input) {
      this.$emit('onChange', input)
    },
    onKeyPress(button) {
      this.$emit('onKeyPress', button)

      /**
       * If you want to handle the shift button
       */
      if (button === '{shift}') {
        this.handleShift()
      }
    },
    handleShift() {
      let currentLayout = this.keyboard.options.layoutName
      let newLayout = currentLayout === 'default' ? 'shift' : 'default'
      let newDisplay =
        currentLayout === 'default' ? this.displayEn : this.displayRu

      this.keyboard.setOptions({
        layoutName: newLayout,
        display: newDisplay,
      })
    },
  },
}
</script>

<style scoped></style>
